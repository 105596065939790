import React from 'react'

import Layout from '../components/Layout'
import VacHero from '../components/VacHero'
import VacOffice from '../components/VacOffice'
import VacList from '../components/VacList'
import VacFeedback from '../components/VacFeedback'

const Vacancies = () => (
  <Layout
    className="layout--vacancies"
    title="Вакансии в студии разработки мобильных приложений AppCraft"
    content="✅ Работа в области разработки мобильных приложений для iPhone, Android. Программисты, менеджеры, дизайнеры, тестировщики, маркетологи"
    keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
    ogSiteName="AppCraft"
    ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
    ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
  >
    <VacHero />
    <VacOffice />
    <VacList />
    <VacFeedback />
  </Layout>
)

export default Vacancies
