import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'

const VacCard = ({ vacancy, onCardClick }) => {
  const { thumbs, icons } = useStaticQuery(graphql`
    query {
      thumbs: allFile(filter: { relativePath: { regex: "/vac-/" } }) {
        edges {
          node {
            publicURL
            relativePath
          }
        }
      }
      icons: allFile(filter: { relativePath: { regex: "/vacico-/" } }) {
        edges {
          node {
            publicURL
            relativePath
          }
        }
      }
    }
  `)
  const cardThumb = thumbs.edges.find((item) => item.node.relativePath === vacancy.thumb)
  const cardIcon = icons.edges.find((item) => item.node.relativePath === vacancy.icon)
  const cardClasses = cn('vaccard', `vaccard--${vacancy.id}`)
  // const statusClasses = cn('vaccard-status', { 'is-active': vacancy.active })

  return (
    <article className={cardClasses} onClick={onCardClick.bind(null, vacancy.id)}>
      <div className="vaccard-body">
        <header className="vaccard-header">
          <img className="vaccard-icon" src={cardIcon.node.publicURL} alt="" />
          <h3 className="vaccard-title">{vacancy.title}</h3>
        </header>
        <p className="vaccard-preview">{vacancy.preview}</p>
        {/* <div className={statusClasses}>
          {vacancy.active ? 'Активная вакансия' : 'Вакансия закрыта'}
        </div> */}
      </div>

      <div className="vaccard-media">
        <img className="vaccard-img" src={cardThumb.node.publicURL} alt="" />
      </div>
    </article>
  )
}

export default VacCard
