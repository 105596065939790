import React from 'react'

import Icon from '../Icon'

const VacHero = () => (
  <section className="vachero">
    <div className="container">
      <h1 className="vachero-title text-22 sm:text-42 font-bold leading-tight">
        Что такое работа в <span className="text-primary"> AppCraft?</span>
      </h1>

      <ul className="vachero-list">
        <li className="vachero-item">
          <div className="vachero-slat">
            <Icon name="vac-analytics" width="56" height="56" />
            <p>
              Это стабильная зарплата, потому что качественный труд должен быть
              справедливо вознагражден
            </p>
          </div>
        </li>

        <li className="vachero-item">
          <div className="vachero-slat">
            <Icon name="vac-chat" width="56" height="56" />
            <p>
              Это когда ты знаешь, что твое мнение выслушают и поймут, а
              проблему – решат
            </p>
          </div>
        </li>

        <li className="vachero-item">
          <div className="vachero-slat">
            <Icon name="vac-success" width="56" height="56" />
            <p>
              Это когда руководитель радуется твоим успехам и помогает
              развиваться
            </p>
          </div>
        </li>

        <li className="vachero-item">
          <div className="vachero-slat">
            <Icon name="vac-product" width="56" height="56" />
            <p>
              Это не просто выполнение всех пунктов ТЗ, а создание работающего
              продукта
            </p>
          </div>
        </li>

        <li className="vachero-item">
          <div className="vachero-slat">
            <Icon name="vac-support" width="56" height="56" />
            <p>
              Это классная команда с нескончаемыми шутками и своевременной
              поддержкой
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
)

export default VacHero
