import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import VacCard from '../VacCard'
import VacDetails from '../VacDetails'

const VacList = () => {
  const [active, setActive] = useState(null)
  const {
    allVacanciesJson: { edges: vacancies },
  } = useStaticQuery(graphql`
    query {
      allVacanciesJson {
        edges {
          node {
            active
            additionalNew
            additionalReq
            duties
            description
            descriptionNew
            icon
            id
            location
            ouroffer
            ourofficeoffer
            preview
            requirements
            skills
            thumb
            title
            url
          }
        }
      }
    }
  `)

  const onCardClick = (id) => {
    if (id === active) return setActive(null)

    setActive(id)
  }

  const renderVacancies = vacancies.map(({ node: vacancy }, key) => (
    <React.Fragment key={key}>
      <VacCard vacancy={vacancy} onCardClick={onCardClick} />
      <VacDetails vacancy={vacancy} active={active} />
    </React.Fragment>
  ))

  return (
    <section className="vaclist container">
      <header className="vaclist-header">
        <h2 className="vaclist-title font-bold leading-tight sm:text-28">Каких сотрудников мы ищем</h2>
        <p className="vaclist-subtitle text-sm sm:text-base">
          О наших открытых вакансиях можно узнать прямо сейчас на <a href="https://ryazan.hh.ru/employer/1726344" rel="nofollow" target="_blank" className="link link--primary">HeadHunter</a>, а здесь – ознакомиться с типовыми требованиями и&nbsp;условиями по всем вакансиям студии.
        </p>
      </header>

      <div className="vaclist-grid">{renderVacancies}</div>
    </section>
  )
}

export default VacList
