import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'

const VacOffice = () => {
  const {
    allFile: { edges: slides },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/office/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 680) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const renderSlides = slides.map(({ node: slide }, key) => (
    <SwiperSlide key={key} className="carousel-slide">
      <Img
        fluid={slide.childImageSharp.fluid}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    </SwiperSlide>
  ))

  return (
    <section className="vacoffice">
      <h2 className="vacoffice-title container font-bold leading-tight sm:text-28">
        Как выглядит наш офис и рабочий процесс
      </h2>

      <Swiper
        className="carousel"
        loop={true}
        roundLengths={true}
        spaceBetween={16}
        slidesPerView="auto"
        centeredSlides={true}
        slideToClickedSlide={true}
        watchSlidesProgress={true}
        watchSlidesVisibility={true}
      >
        {renderSlides}
      </Swiper>
    </section>
  )
}

export default VacOffice
