import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { animated, useTransition } from 'react-spring'

import Icon from '../Icon'

const VacDetails = ({ vacancy, active }) => {
  const {
    allFile: { edges: icons },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/vacico-/" } }) {
        edges {
          node {
            publicURL
            relativePath
          }
        }
      }
    }
  `)
  const transitions = useTransition(active === vacancy.id, {
    config: { duration: 250, mass: 5, tension: 300, friction: 100 },
    from: { position: 'absolute', opacity: 0, transform: 'scale(0.95)' },
    enter: { opacity: 1, position: 'relative', transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.95)' },
    trail: 25,
  })

  const icon = icons.find((item) => item.node.relativePath === vacancy.icon)

  return transitions(
    (props, item, key) =>
      item && (
        <animated.div key={key} style={props} className="vacdetails">
          <header className="vacdetails-header">
            <img className="vacdetails-icon" src={icon.node.publicURL} alt="" width="86" height="86" />
            <p className="vacdetails-location text-xs sm:text-base text-primary leading-tight">
              {vacancy.location}
            </p>
            <h3 className="vacdetails-title font-bold leading-tight sm:text-28">{vacancy.title}</h3>
            <div className="vacdetails-cta">
              <a
                className="btn btn--primary w-full sm:w-auto"
                href={vacancy.active ? vacancy.url : null}
                disabled={!vacancy.active}
              >
                <Icon name="hh" width="18" height="18" className="fill-current mr-3" />
                Откликнуться
              </a>
            </div>
          </header>

          {vacancy.descriptionNew && (
            <section className="vacdetails-section">
              <p className="font-bold mb-2">О команде AppCraft:</p>
              <p dangerouslySetInnerHTML={{ __html: vacancy.descriptionNew }}></p>
            </section>
          )}

          {vacancy.description && (
            <section className="vacdetails-section">
              <p className="font-bold mb-2">Кого ищем:</p>
              <p dangerouslySetInnerHTML={{ __html: vacancy.description }}></p>
            </section>
          )}

          <section className="vacdetails-section">
            <p className="font-bold mb-2">Чем тебе предстоит заниматься:</p>
            <ul>
              {vacancy.duties.map((item, key) => (
                <li key={key}>{item}</li>
              ))}
            </ul>
          </section>

          <section className="vacdetails-section">
            <p className="font-bold mb-2">Что необходимо от тебя</p>
            <ul>
              {vacancy.requirements.map((item, key) => (
                <li key={key}>{item}</li>
              ))}
            </ul>
          </section>

          {vacancy.additionalNew && <section className="vacdetails-section">{vacancy.additionalNew}</section>}

          {vacancy.additionalReq && (
            <section className="vacdetails-section">
              <p className="font-bold mb-2">Будет плюсом:</p>
              <ul>
                {vacancy.additionalReq.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            </section>
          )}

          <section className="vacdetails-section">
            <p className="font-bold mb-2">Что мы предлагаем:</p>
            <ul>
              {vacancy.ouroffer.map((item, key) => (
                <li key={key}>{item}</li>
              ))}
            </ul>

            {vacancy.ourofficeoffer && (
              <>
                <p>А для офисных сотрудников, помимо вышеописанного, еще:</p>
                <ul>
                  {vacancy.ourofficeoffer.map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </>
            )}
          </section>

          <section className="vacdetails-section">
            <p className="font-bold mb-2">Ключевые навыки</p>
            <ul className="vacdetails-skilllist">
              {vacancy.skills.map((item, key) => (
                <li key={key}>{item}</li>
              ))}
            </ul>
          </section>
        </animated.div>
      )
  )
}

VacDetails.propTypes = {}

VacDetails.defaultProps = {}

export default VacDetails
