import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const VacFeedback = () => {
  const { media } = useStaticQuery(graphql`
    query {
      media: file(relativePath: { eq: "vacfeedback-media.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="vacfeedback container">
      <div className="vacfeedback-body">
        <h2 className="vacfeedback-title">Нет нужной вакансии?</h2>
        <div className="vacfeedback-content">
          <p>
            Если подходящей вакансии не нашлось, но очень хочется стать частью нашей команды – пиши на почту
            нашему hr-директору, и отклик будет рассмотрен.
          </p>
          <p>Мы всегда стараемся найти способы сотрудничества, если оно кажется перспективным.</p>
          <a className="link link--primary" href="mailto:sa@appcraft.pro">
            sa@appcraft.pro
          </a>
        </div>
      </div>
      <div className="vacfeedback-media">
        <img src={media.publicURL} alt="" width="417" height="388" />
      </div>
    </section>
  )
}

export default VacFeedback
